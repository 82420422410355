import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import {ErrorBoundary} from "./shared/components/ErrorBoundary";
import './index.css';
import './assets/less/index.less';
import 'semantic-ui-less/semantic.less';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { configureStore } from './shared/redux/store';
import rootReducer from './shared/redux/reducers';

const store = configureStore(rootReducer);
ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ReduxProvider store={store}>
        <App/>
      </ReduxProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
