import {Field} from "react-final-form";
import React from "react";
import "./RiField.less";
import PropTypes from "prop-types";

const composeValidators = (validators) => value =>
  validators.reduce((error, validator) => error || (validator && validator(value)), undefined);

const RiField = ({children, label, name, required, pattern, className, validator, placeholder})=>{

  const validationMethods = {
    required: value => (value === null || value === undefined || value === '')  ?  "Please enter value.": undefined,
    pattern: value => (new RegExp(pattern)).test(value) ? undefined : "Please enter valid value."
  }

  const fieldValidators = [
    required?validationMethods.required:null,
    pattern?validationMethods.pattern:null,
    validator
  ];

  return <Field name={name} validate={composeValidators(fieldValidators)}>
    {({input, meta}) =>{
      const hasError = meta.error &&
        (meta.submitting || meta.submitFailed || meta.submitSucceeded);
      const validationMessageField = (label || placeholder || 'value')
        .replace(new RegExp('select', 'i'),'')
        .replace(new RegExp('enter', 'i'),'')
        .replace(new RegExp('add', 'i'),'')
        .replace(new RegExp('your', 'i'),'')
        .toLowerCase();
      return <div className={`ri-field field ${hasError?'error':''} ${className}`}>
        {
          label? <>
                <div className="label">
                  {label} {required?<span className="required">*</span>:null}
                </div>
              <br/>
            </>: null
        }
        <div className="ui input">
          {children({input, meta, hasError})}
        </div>
        { hasError && <span className="validation-message">
            {meta.error.replace('value', validationMessageField)}
          </span>
        }
      </div>
    }}
  </Field>
}


RiField.defaultProps = {
  label:'',
  required: false,
  pattern: '',
  className: '',
  validator: null,
};

RiField.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  validator: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  pattern: PropTypes.any,
  className: PropTypes.string,
};


export default RiField;
