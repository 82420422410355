import {callAPI} from "../../api-call";
import {getItem, getJSONItem, removeItem, setItem, setJSONItem} from "../../storage";
import {ACCESS_TOKEN, REFRESH_TOKEN, USER_KEY} from "../../storage-keys";

export const AUTH_ACTIONS = {
  SET_USER: 'SET_USER',
  REMOVE_USER: 'REMOVE_USER'
};

export const getUserData= ()=>{
  return getJSONItem(USER_KEY);
}

const removeKeys = ()=>{
  removeItem(USER_KEY);
  removeItem(ACCESS_TOKEN);
  removeItem(REFRESH_TOKEN);
};

const setKeys = ({userInfo, accessToken, refreshToken})=>{
  if(userInfo){
    setJSONItem(USER_KEY, userInfo);
  }
  if(accessToken){
    setItem(ACCESS_TOKEN, accessToken);
  }
  if(refreshToken){
    setItem(REFRESH_TOKEN, refreshToken);
  }
};

export const setUserData = ({dispatch, userInfo, accessToken, refreshToken})=>{
  setKeys({userInfo, accessToken, refreshToken});
  dispatch({
    type: AUTH_ACTIONS.SET_USER,
    payload: userInfo
  });
};

const removeUserData = (dispatch)=>{
  removeKeys();
  dispatch ({
    type: AUTH_ACTIONS.REMOVE_USER
  });
};

export const checkLogin = () => async (dispatch) => {
  try {
    const userInfo = await callAPI({method: 'post', url: '/checkLogin', hideErrorMessage: true});
    setUserData({userInfo, dispatch});
  } catch(e){
    try {
      const {userInfo, accessToken, refreshToken} = await callAPI({
        method: 'put',
        url: '/refreshToken',
        hideErrorMessage: true,
        headers: {refreshtoken: `Bearer ${getItem(REFRESH_TOKEN)}`}
      });
      setUserData({userInfo, accessToken, refreshToken, dispatch});
    } catch(error){
      removeUserData(dispatch);
    }
  }
};

export const logout = () => async (dispatch) => {
  removeUserData(dispatch);
};

export const doLogin = ({userData: userInfo, accessToken, refreshToken}) => (dispatch) => {
  setUserData({userInfo, accessToken, refreshToken, dispatch});
};
