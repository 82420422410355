import { AUTH_ACTIONS } from "../actions/authAction";

const initialAppState = {user: null};

const authReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case AUTH_ACTIONS.SET_USER:
      return { ...state, user: action.payload };

    case AUTH_ACTIONS.REMOVE_USER:
      return { ...state, user: null };

    default:
      return state;
  }
};
export default authReducer;
