import {FILE_UPLOAD_STATUSES} from "../shared/constants";

const getDealData = (apiData) => {
  const {DealTitle, DealDescription, DealOrderID,DealNumber} = apiData;
  return {
    values: {DealTitle, DealDescription, DealOrderID,DealNumber},
    valid: true,
  }
}

const getCustomerData = (apiData) =>{
  const {CustomerName, CustomerID, CustomerDetails, CustomerType, CustomerExists} = apiData;
  if(!CustomerExists){
    CustomerDetails.Branch = {value: {BranchName: CustomerDetails.Branch, BranchID: CustomerDetails.BranchID}, label: CustomerDetails.Branch};
    CustomerDetails.SalesRep = {value: {
      PrefFullName: CustomerDetails.SalesRep,
      SalesRepID:CustomerDetails.SalesRepID && Number(CustomerDetails.SalesRepID),
      IsManager:CustomerDetails.IsManager,
      SalesRepEmail:CustomerDetails.SalesRepEmail,
      ManagerEmail:CustomerDetails.ManagerEmail,
      },
      label: CustomerDetails.SalesRep};
    CustomerDetails.CustomerType = {value: {CustomerType: CustomerDetails.CustomerType}, label: CustomerDetails.CustomerType};
  }
  return {
    valid: true,
    values: {
      Customer:{
        value:{ CustomerName, CustomerID, CustomerType, ...CustomerDetails, isManual: !CustomerExists },
        label: apiData.CustomerName
      }
  }};
}

const getLocationData = (apiData) =>{
  const {Locations} = apiData;
  const locationData =  {
    valid: true,
    values: { Locations:[]}
  };
  Locations.forEach((location)=>{
    locationData.values.Locations.push({
      LocationUIID:location.LocationUIID,
      Location:{
        value: { ...location, isManual: !location.LocationExists, UIID: location.LocationID || `${location.LocationUIID}--`},
        label: location.LocationName
      },
      ContactInfo:{
        ContactName :location.ContactName,
        ContactEmail :location.ContactEmail,
        ContactPhone :location.ContactPhone
      }
    })
  })
  return locationData;
}

const getAccessories = ({AccessoriesAPIData, MainframeUIID}) => {
  const accessories = [];
  AccessoriesAPIData.forEach((accessory)=>{
    if(accessory.MainframeUIID === MainframeUIID) {
      if(!accessory.AccessoryExists){
        // eslint-disable-next-line no-param-reassign
        accessory.Manufacturer = {value: {Manufacturer: accessory.Manufacturer}, label: accessory.Manufacturer};
      }
      accessories.push({
        AccessoryUIID: accessory.AccessoryUIID,
        Accessory:{
          value: { ...accessory, isManual: !accessory.AccessoryExists, UIID: accessory.ItemID || `${accessory.AccessoryUIID}--`},
          label:`${accessory.ItemNumber} - ${accessory.Description}`
        },
        Quantity: accessory.Quantity,
        Price: accessory.Price,
        Total: accessory.Total,
      })
    }
  });
  return accessories;
}

const getLocationMainframeData = ({MainframeAPIData, LocationUIID,AccessoriesAPIData }) => {
  const locationMainframes = [];
  MainframeAPIData.forEach(({MainframeUIID, ...mainframe})=>{
    if(mainframe.LocationUIID === LocationUIID) {
      if(!mainframe.MainframeExists){
        // eslint-disable-next-line no-param-reassign
        mainframe.Manufacturer = {value: {Manufacturer: mainframe.Manufacturer}, label: mainframe.Manufacturer};
      }
      locationMainframes.push({
        MainframeUIID,
        Accessories: getAccessories({AccessoriesAPIData, MainframeUIID}),
        MainFrame:{
          value: { ...mainframe, isManual: !mainframe.MainframeExists, UIID: mainframe.ItemID || `${mainframe.MainframeUIID}--`},
          label:`${mainframe.ItemNumber} - ${mainframe.Description}`
        },
        Quantity: mainframe.Quantity,
        Price: mainframe.Price,
        Total: mainframe.Total,
      })
    }
  })
  return locationMainframes;
}

const getMainframeData = (apiData) => {
  const mainframeData = {};
  const {Locations, Mainframe: MainframeAPIData, Accessories: AccessoriesAPIData} = apiData;
  Locations.forEach(({LocationUIID})=>{
    mainframeData[LocationUIID] = {
      valid: true,
      values: {
        MainFrames:getLocationMainframeData({
          MainframeAPIData, AccessoriesAPIData, LocationUIID
        })
      }
    }
  });
  return mainframeData;
}

const getOrderData = (apiData)=>{
  const {OrderType, EADeliveryMethod, OrderTypeID ,Note} = apiData;
  const orderData =  {
    valid: true,
    values: {
      OrderType:{
        value:{ OrderType, OrderTypeID },
        label: OrderType
      },
      DeliveryMethod:{
        value: EADeliveryMethod,
        label: EADeliveryMethod
      },
      Note
    }};
  return orderData;
}

const getAttachmentData = (apiData) =>{
  const {Attachments} = apiData;
  // eslint-disable-next-line no-param-reassign,no-return-assign
  Attachments.forEach(attachment=> attachment.status = FILE_UPLOAD_STATUSES.UPLOADED);
  return Attachments;
}

// eslint-disable-next-line import/prefer-default-export
export const getFormObject = (apiData) => {
  return {
    orderStatus:apiData.OrderStatus,
    dealDetails: getDealData(apiData),
    customerData: getCustomerData(apiData),
    locationData: getLocationData(apiData),
    mainframeData: getMainframeData(apiData),
    orderData: getOrderData(apiData),
    attachments: getAttachmentData(apiData)
  };
}
