export const FORMS = {
  DEAL_FORM: 'DEAL_FORM',
  CUSTOMER_FORM: 'CUSTOMER_FORM',
  MAINFRAME_FORM: 'MAINFRAME_FORM',
  LOCATION_FORM: 'LOCATION_FORM',
  ORDER_FORM: 'ORDER_FORM'
}

export const ORDER_STATUSES = {
  SENT: 'Sent',
  DRAFT: 'Draft'
}

export const DEAL_NAME_PREFIX='DEAL-';

export const FILE_UPLOAD_STATUSES = {
  IN_QUEUE: 'IN_QUEUE',
  IN_PROGRESS: 'IN_PROGRESS',
  ERROR:'ERROR',
  UPLOADED: 'UPLOADED'
}
