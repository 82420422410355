export const UI_ACTIONS = {
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  WINDOW_RESIZE: 'WINDOW_RESIZE',
  SET_LOADER_MSG: 'SET_LOADER_MSG',
};

export const showLoader = () => {
  return {
    type: UI_ACTIONS.SHOW_LOADER
  };
};

export const hideLoader = () =>{
  return {
    type: UI_ACTIONS.HIDE_LOADER
  };
};

export const setLoaderMessage=(message)=>{
  return{
    type:UI_ACTIONS.SET_LOADER_MSG, payload:message
  }
};

export const windowResizeEvent = ()=>{
  return {
    type: UI_ACTIONS.WINDOW_RESIZE,
    payload: {height: window.innerHeight, width: window.innerWidth}
  };
};

