import {find, remove, findIndex, get} from "lodash";
import {DEAL_ORDER_ACTIONS} from "../actions/dealOrderAction";
import {FORMS,ORDER_STATUSES} from "../../shared/constants";

const initialAppState = {
  step: 1,
  isEdit: false,
  manualOptions:{
    customerOptions:[],
    locationOptions:[],
    mainframeOptions:[],
    accessoryOptions:[]
  },
  dealFormAPIResponse:{},
  submitStatuses: {
    [FORMS.CUSTOMER_FORM]: false,
    [FORMS.LOCATION_FORM]: false,
    [FORMS.MAINFRAME_FORM]: false,
    [FORMS.ORDER_FORM]: false,
    [FORMS.DEAL_FORM]: false,
  },
  dealForm:{
    isExcessiveDeal:true,
    orderStatus: ORDER_STATUSES.DRAFT,
    attachments:[],
    dealDetails:{},
    customerData:{},
    mainframeData:{},
    orderData:{},
    locationData:{valid:true, values:{Locations:[]}}
  },
  dealSheetJSON:{}
};

// eslint-disable-next-line no-unused-vars
const processMainframeData = ({payload, state})=>{
  const mainframeLocations = [...state.dealForm.mainframeData.values.Locations];
  const mainframeData = {...state.dealForm.mainframeData};
  const locations = [...payload.values.Locations];
  locations.forEach((location)=>{
    const locationFoundIndex = findIndex(mainframeLocations, {LocationUIID: location.LocationUIID});
    const newLocation = {
      LocationUIID: location.LocationUIID,
      Location:{...get(location, 'Location.value')}
    };
    if(locationFoundIndex === -1){
      mainframeLocations.push(newLocation);
    } else {
      mainframeLocations[locationFoundIndex] = newLocation;
    }
  });

  mainframeLocations.forEach((location)=>{
    const locationFound = find(locations, {LocationUIID: location.LocationUIID});
    if(!locationFound){
      remove(mainframeLocations, {LocationUIID: location.LocationUIID});
    }
  });

  mainframeData.values.Locations = mainframeLocations;
  return { ...state, dealForm: {...state.dealForm, locationData: payload, mainframeData } };
}

const resetAccessoriesData = ({mainframeUIID, locationUIID, state})=>{
  const locationMainframeData = {...state.mainframeData[locationUIID]};
  const mainframeData = find(locationMainframeData.values.MainFrames, {MainframeUIID: mainframeUIID});
  mainframeData.Accesssories = [];
  return { ...state, dealForm:{...state.dealForm, [locationUIID]: locationMainframeData}};
}

const removeDeletedLocationsFromMainFromData = ({state, newLocationData})=>{
  const oldLocationData = state.dealForm.locationData;
  const newMainframeData = {...state.dealForm.mainframeData};
  oldLocationData.values.Locations.forEach((location)=>{
    const foundLocation = find(newLocationData.values.Locations, {LocationUIID: location.LocationUIID});
    if(!foundLocation){
      delete newMainframeData[location.LocationUIID];
    }
  });
  return newMainframeData
}

const setAttachmentStatus = ({attachment, status, state})=>{
  const newAttachments = [...state.dealForm.attachments];
  if(find(newAttachments,{UIID: attachment.UIID})){
    find(newAttachments,{UIID: attachment.UIID}).status = status;
  }
  return { ...state, dealForm:{...state.dealForm, attachments: newAttachments} };
}

const setAttachmentData = ({attachment, data, state})=>{
  const newAttachments = [...state.dealForm.attachments];
  const index = findIndex(newAttachments,{UIID: attachment.UIID});
  newAttachments[index] = {...newAttachments[index], ...data}; // update the attachment data.
  return { ...state, dealForm:{...state.dealForm, attachments: newAttachments} };
}

const dealOrderReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case DEAL_ORDER_ACTIONS.CHANGE_STEP:
      return { ...state, step: action.payload };
    case DEAL_ORDER_ACTIONS.UPDATE_DEAL_DETAILS:
      return { ...state, dealForm: {...state.dealForm, dealDetails: action.payload} };
    case DEAL_ORDER_ACTIONS.UPDATE_CUSTOMER_DATA:
      return { ...state, dealForm: {...state.dealForm, customerData: action.payload} };
    case DEAL_ORDER_ACTIONS.UPDATE_LOCATION_DATA:
      // eslint-disable-next-line no-case-declarations
      const newMainframeData = removeDeletedLocationsFromMainFromData({state, newLocationData: action.payload});
      return { ...state, dealForm: {...state.dealForm, locationData: action.payload, mainframeData: newMainframeData } };
    case DEAL_ORDER_ACTIONS.UPDATE_MAINFRAME_DATA:
      // eslint-disable-next-line no-case-declarations
      const mainframeData = {...state.dealForm.mainframeData};
      mainframeData[action.payload.LocationUUID] = action.payload.mainframeData;
      return { ...state, dealForm: {...state.dealForm, mainframeData} };
    case DEAL_ORDER_ACTIONS.UPDATE_ORDER_DATA:
      return { ...state, dealForm: {...state.dealForm, orderData: action.payload} };
    case DEAL_ORDER_ACTIONS.SET_FULL_DEAL_DATA:
      return { ...state, dealForm: {...state.dealForm, ...action.payload} };
    case DEAL_ORDER_ACTIONS.SET_IS_EDIT:
      return { ...state, isEdit: action.payload };
    case DEAL_ORDER_ACTIONS.RESET_DEAL_DATA:
      return initialAppState;
    case DEAL_ORDER_ACTIONS.ADD_CUSTOMER_MANUAL_OPTIONS:
      return { ...state, manualOptions:{...state.manualOptions, customerOptions:action.payload} };
    case DEAL_ORDER_ACTIONS.ADD_LOCATION_MANUAL_OPTIONS:
      return { ...state, manualOptions:{...state.manualOptions, locationOptions:action.payload} };
    case DEAL_ORDER_ACTIONS.ADD_MAINFRAME_MANUAL_OPTIONS:
      return { ...state, manualOptions:{...state.manualOptions, mainframeOptions:action.payload} };
    case DEAL_ORDER_ACTIONS.ADD_ACCESSORY_MANUAL_OPTIONS:
      return { ...state, manualOptions:{...state.manualOptions, accessoryOptions:action.payload} };
    case DEAL_ORDER_ACTIONS.SET_ATTACHMENTS:
      return { ...state, dealForm:{...state.dealForm, attachments: action.payload || []} };
    case DEAL_ORDER_ACTIONS.SET_ATTACHMENT_STATUS:
      return setAttachmentStatus({attachment: action.payload.attachment, status: action.payload.status, state});
    case DEAL_ORDER_ACTIONS.SET_ATTACHMENT_DATA:
      return setAttachmentData({attachment: action.payload.attachment, data: action.payload.data, state});
    case DEAL_ORDER_ACTIONS.SET_FORM_SUBMIT_STATUS:
      return { ...state, submitStatuses:{...state.submitStatuses, [action.payload.form]: [action.payload.status]}};
    case DEAL_ORDER_ACTIONS.RESET_LOCATIONS:
      return { ...state, dealForm:{...state.dealForm, locationData: initialAppState.dealForm.locationData}};
    case DEAL_ORDER_ACTIONS.RESET_MAINFRAMES:
      return { ...state, dealForm:{...state.dealForm, mainframeData: initialAppState.dealForm.mainframeData}};
    case DEAL_ORDER_ACTIONS.RESET_ACCESSORIES:
      return resetAccessoriesData({mainframeUIID: action.payload.mainframeUIID, locationUIID: action.payload.locationUIID, state});
    case DEAL_ORDER_ACTIONS.SET_ALL_FORM_SUBMIT_STATUS:
      // eslint-disable-next-line no-case-declarations
      const submitStatuses = {...state.submitStatuses};
      Object.keys({...submitStatuses}).forEach((key)=>{
        submitStatuses[key] = action.payload;
      });
      return { ...state, submitStatuses};
    case DEAL_ORDER_ACTIONS.SET_DEAL_SHEET_JSON:
      return { ...state, dealSheetJSON: action.payload};
    case DEAL_ORDER_ACTIONS.RESET_DEAL_SHEET_JSON:
      return { ...state, dealSheetJSON: {}};
    case DEAL_ORDER_ACTIONS.CONFIRM_EXCESSIVE_DEAL:
      return {...state, dealForm:{...state.dealForm, isExcessiveDeal: action.payload }}
    case DEAL_ORDER_ACTIONS.SET_ORDER_STATUS:
      return {...state, dealForm:{...state.dealForm, orderStatus: action.payload }}
    default:
      return state;
  }
};
export default dealOrderReducer;
