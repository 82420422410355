import React from "react";
import PropTypes from "prop-types";
import {Checkbox,Radio} from "semantic-ui-react";
import {Field} from "react-final-form";
import RiField from "../RiField/RiField";
import {VALIDATION_PATTERN} from "../../../constants";
import {nonZeroValidator ,phoneValidator} from "../../../validator";

const defaultInputProps = {
  validator: null,
  placeholder:'',
  label:'',
  required: false,
  validateNonZero: false,
  inputLength:0
};

const inputProtoTypes = {
  placeholder: PropTypes.string,
  validateNonZero: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  validator: PropTypes.func,
  required: PropTypes.bool,
  inputLength:PropTypes.number
};

export const RiInput = ({name,  placeholder, required, label, ...props})=>
  <RiField name={name} label={label} required={required} placeholder={placeholder}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    {({input}) => <input placeholder={placeholder} {...input} {...props}/>}
  </RiField>;
RiInput.propTypes = inputProtoTypes;
RiInput.defaultProps = defaultInputProps;

export const RiNumberInput = ({name, placeholder, required, label, validateNonZero, disabled})=>
  <RiField name={name} label={label} required={required} pattern={VALIDATION_PATTERN.NUMBER}
           placeholder={placeholder}
           validator={validateNonZero? nonZeroValidator:  null}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    {({input}) => <input placeholder={placeholder} disabled={disabled} {...input}/>}
  </RiField>;
RiNumberInput.propTypes = inputProtoTypes;
RiNumberInput.defaultProps = defaultInputProps;


const set2DigitDecimal =(event)=>  {
  const t = event.target.value;
  // eslint-disable-next-line no-param-reassign
  event.target.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
  return event;
};

export const RiFloatInput = ({name, placeholder, required, label, validateNonZero})=>
  <RiField name={name} label={label} required={required} pattern={VALIDATION_PATTERN.FLOAT}
           placeholder={placeholder}
           validator={validateNonZero? nonZeroValidator:  null}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    {({input}) =>{
     return <input {...input} placeholder={placeholder} onChange={(event)=> input.onChange(set2DigitDecimal(event)) } />}}
  </RiField>;
RiFloatInput.propTypes = inputProtoTypes;
RiFloatInput.defaultProps = defaultInputProps;

export const RiPhone = ({name, placeholder, required, label})=>
  <RiField name={name} label={label} required={required} validator={phoneValidator}
           placeholder={placeholder}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    {({input}) =>{
      return <input {...input} placeholder={placeholder} onChange={(event)=> input.onChange(set2DigitDecimal(event)) } />}}
  </RiField>;
RiPhone.propTypes = inputProtoTypes;
RiPhone.defaultProps = defaultInputProps;


export const RiEmail = ({name, placeholder, required, label})=>
  <RiField name={name} label={label} required={required}
           placeholder={placeholder}
           pattern={VALIDATION_PATTERN.EMAIL}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    {({input}) =><input placeholder={placeholder} {...input}/>}
  </RiField>;
RiEmail.propTypes = inputProtoTypes;
RiEmail.defaultProps = defaultInputProps;

export const RiPassword = ({name, placeholder, label, required})=>{
  return <RiField name={name} label={label} placeholder={placeholder} required={required}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    {({input}) => <input type="password" placeholder={placeholder} {...input}/>}
  </RiField>
};
RiPassword.propTypes = inputProtoTypes;
RiPassword.defaultProps = defaultInputProps;

export const RiZip=({name, placeholder, required, label})=>{
  return <RiField name={name} placeholder={placeholder} label={label} required={required} pattern={VALIDATION_PATTERN.ZIP}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    {({input})=><input placeholder={placeholder} autoComplete="new-password" {...input} />}
  </RiField>
}
RiZip.propTypes=inputProtoTypes;
RiZip.defaultProps=defaultInputProps;

const setMaximumInputLength=(event, inputLength)=>{
  if(inputLength){
    const inputValue=event.target.value;
    // eslint-disable-next-line no-param-reassign
    event.target.value=(inputValue.toString().length >inputLength )? inputValue.substr(0,inputLength) : inputValue;
    return event;
  }
  return event;
};

export const RiTextArea = ({name, placeholder, required, label,inputLength})=>
  <RiField name={name} label={label} required={required} placeholder={placeholder}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    {({input}) => <textarea placeholder={placeholder} {...input} onChange={(event)=> input.onChange(setMaximumInputLength(event,inputLength))}/>}
  </RiField>;
RiTextArea.propTypes = inputProtoTypes;
RiTextArea.defaultProps = defaultInputProps;

export const RiCheckBox = ({name, label})=>
  <Field name={name}>
      {
        ({input}) => <div>
          <Checkbox
                            label={label}
                            onChange={()=>input.onChange(!input.value)}
                            checked={!!input.value}/>
        </div>
      }
  </Field>;

RiCheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};


export const RiRadioGroup=({name ,label,required, radioLabel1, radioLabel2,radioValue1,radioValue2})=>
  <RiField name={name} label={label} required={required}>
    {
      ()=> <div>
            <RiRadio name={name} label={radioLabel1} value={radioValue1} />
            <RiRadio name={name} label={radioLabel2} value={radioValue2} />
          </div>

    }
  </RiField>
RiRadioGroup.defaultProps={
  required:false
}
RiRadioGroup.propTypes={
  name:PropTypes.string.isRequired,
  label:PropTypes.string.isRequired,
  radioLabel1:PropTypes.string.isRequired,
  radioLabel2:PropTypes.string.isRequired,
  radioValue1:PropTypes.bool.isRequired,
  radioValue2:PropTypes.bool.isRequired,
  required:PropTypes.bool
};

const handleDefaultCheckOption=(input,value)=>{
  if(input.value===false || input.value===true){
    return value===!!input.value
  }
    input.onChange(value);
    return value

};

const RiRadio=({ name ,label ,value})=><Field name={name}>
    {
      ({input})=><div className='radio-button'>
          <Radio
            label={label}
            name={name}
            value={value}
            checked={handleDefaultCheckOption(input,value)}
            onChange={()=>{input.onChange(!input.value)}}
          />
        </div>
    }
  </Field>;

RiRadio.propTypes={
  name:PropTypes.string.isRequired,
  label:PropTypes.string.isRequired,
  value:PropTypes.bool.isRequired,
};
