import { combineReducers } from "redux";
import commonUIReducer  from "./commonUIReducer";
import authReducer  from "./authReducer";
import gridReducer from "./gridReducer";
import dealOrderReducer from "../../../modules/deals/redux/reducers/dealOrderReducer";

const rootReducer = combineReducers({
  commonUI: commonUIReducer,
  auth: authReducer,
  gridData: gridReducer,
  dealOrder: dealOrderReducer
});

export default rootReducer;
