import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {windowResizeEvent as windowResizeEventAction} from "../redux/actions/commonUIAction";

const WindowResizeListener = ({windowResizeEvent})=>{
  useEffect(()=>{
    window.addEventListener('resize', windowResizeEvent);
    windowResizeEvent();
  });
  return <>
    <div style={{display:'none'}} />
  </>;
}

WindowResizeListener.propTypes = {
  windowResizeEvent: PropTypes.func.isRequired
};

export default connect(null, {windowResizeEvent: windowResizeEventAction})(WindowResizeListener);
