import React from "react";
import {Form, FormSpy} from "react-final-form";
import PropTypes from "prop-types";
import {Form as SemanticForm} from "semantic-ui-react";
import arrayMutators from 'final-form-arrays';

const RiForm = ({children, onSubmit, isSubmitted, onChange, initialValues, validate, style})=>{
  const emptyMethod = ()=>{
    // DO Nothing
  };

  const onUpdateFormData = (state)=>{
    if(onChange){
      onChange(state)
    }
  }

  const onSubmitCall = async (data1, data2)=>{
    if(onSubmit){
      await onSubmit(data1, data2);
    }
  }

  const setIsSubmitted = (args, state) => {
    // eslint-disable-next-line no-param-reassign
    state.formState.submitSucceeded = isSubmitted;
  }

  const setFieldValue = ([field, value], state, {changeValue}) =>{
    changeValue(state, field, () => value);
  }

  let setSubmitStatus;
  setTimeout(()=>{
    if(isSubmitted && setSubmitStatus){
      setSubmitStatus()
    }
  }, 200);

  return <Form
    mutators={{...arrayMutators, setIsSubmitted, setFieldValue}}
    onSubmit={onSubmitCall}
    keepDirtyOnReinitialize
    initialValues={initialValues || {}}
    validate={validate || emptyMethod}
    render={({handleSubmit, ...otherFormData}) => {
      setSubmitStatus = otherFormData.form.mutators.setIsSubmitted;
      return(
        <SemanticForm onSubmit={handleSubmit} style={style} className="ri-form">
          <FormSpy subscription={{values: true, valid: true}}
                   onChange={state => onUpdateFormData(state)} />
          {children({handleSubmit, setFieldValue: otherFormData.form.mutators.setFieldValue, ...otherFormData})}
        </SemanticForm>
      )
    }}
  />
}

RiForm.defaultProps = {
  onSubmit: null,
  onChange: null,
  style: {},
  isSubmitted: false,
  initialValues: {},
  validate: null
}

RiForm.propTypes = {
  children: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  isSubmitted: PropTypes.bool,
  style: PropTypes.shape({}),
  initialValues: PropTypes.shape({}),
  validate: PropTypes.oneOf({}, PropTypes.func)
};

export default RiForm;
