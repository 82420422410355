import React from 'react';
import {sendErrorMail} from "../../modules/deals/shared/deal-services";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.error(error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

    componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
      sendErrorMail({message:error.message,name:'Front-End-Error', stack:error.stack});
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
