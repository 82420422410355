// in src/App.js
import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {
  HashRouter,
  Switch
} from 'react-router-dom';
import {connect} from "react-redux";
import Login from './modules/auth/login/Login';
import RenderRoutes from "./shared/RenderRoutes";
import {StaticSpinner} from "./shared/components";
import {checkLogin as checkLoginAction} from "./shared/redux/actions/authAction";
import WindowResizeListener from "./shared/components/WindowResizeListener";

const baseRoutes = [
  {
    path: '/dashboard',
    component: React.lazy(() => import(/* webpackChunkName: "dashabord-module" */'./modules/dashboard/DashboardRoutes.js')),
    authenticated: true
  },
  {
    path: '/deals',
    component: React.lazy(() => import(/* webpackChunkName: "deals-module" */'./modules/deals/DealRoutes.js')),
    authenticated: true
  },
  {
    path: '/login',
    component: Login,
    authenticated: false
  }
];

const App = ({checkLogin}) => {
  const [checkAuth, setCheckAuth] = useState(false);
  const setCheckAuthData = () => {
      (async () => {
        try {
          await checkLogin();
        } catch (e) {
          // Do nothing
        }
        setCheckAuth(true);
      })();
  }

  useEffect(()=>{
    setCheckAuthData();
  });

  return (checkAuth ?
    <React.Suspense fallback={<StaticSpinner/>}>
      <WindowResizeListener />
      <HashRouter>
        <Switch>
          <RenderRoutes routes={baseRoutes}/>
        </Switch>
      </HashRouter>
    </React.Suspense>
  :<StaticSpinner/>);
};

App.propTypes = {
  checkLogin: PropTypes.func.isRequired
};
export default connect(null, {checkLogin: checkLoginAction})(App);
