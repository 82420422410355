import {callAPI} from "../../../shared/api-call";
import {getAPIObject} from "../formDataProcessor";

export const updateAPICall = async ({apiOrderStatus, updateDealId, customerData, dealData, locationData, mainframeData, orderData, attachments})=>{
  // eslint-disable-next-line no-return-await
  return await callAPI({
    url:`/deals/${updateDealId}`,
    method:'put',
    showLoading: true,
    body: getAPIObject({customerData, dealData, locationData, mainframeData, orderStatus: apiOrderStatus, orderData, attachments})
  });
}


export const createAPICall = async ({apiOrderStatus, customerData, dealData, locationData, mainframeData, orderData, attachments})=>{
  // eslint-disable-next-line no-return-await
  return await callAPI({
    url:'/deals/',
    method:'post',
    showLoading: true,
    body: getAPIObject({customerData, dealData, locationData, mainframeData, orderStatus: apiOrderStatus, orderData, attachments})
  });
}

export const uploadFile = async ({file, dealId, dealOrderId})=>{
  const formData = new FormData();
  formData.append('files', file.fileObj);
  // eslint-disable-next-line no-await-in-loop,no-return-await
  return await callAPI({
    url: '/deals/attachment',
    method: 'POST',
    queryParams: {dealId, dealOrderId, UIID: file.UIID},
    formData
  });
}

export const deleteAttachment = async (attachmentId) => {
  // eslint-disable-next-line no-return-await
  return await callAPI({
    // eslint-disable-next-line no-underscore-dangle
    url: `/deals/attachment/${attachmentId}`,
    method: 'DELETE'
  });
};

export const validateDealSheet=async  ({sheetJSONObj,customerDetails})=> {
  // eslint-disable-next-line no-return-await
  return await callAPI({
    url: '/deals/sheet/validate',
    method: 'POST',
    showLoading: true,
    showMessage:'Please wait, we are loading your file.',
    body: {...sheetJSONObj, customerDetails}
  });
};
export const sampleDealSheet=async (customerId)=>{
  // eslint-disable-next-line no-return-await
  return await callAPI({
    url:'/deals/sheet/sample',
    method:'GET',
    showLoading: true,
    showMessage: 'Please wait, downloading the sample file for you.',
    queryParams:{customerId}
  })
};

export const sendErrorMail=async ({message,name,stack})=>{
  // eslint-disable-next-line no-return-await
  return await callAPI({
    url:'/error/mail',
    method:'POST',
    body:{message,name,stack},
    hideErrorMessage: true,
  })
}
