import axios from 'axios';
import {HttpError} from "react-admin";
import {getItem} from './storage';
import {ACCESS_TOKEN, REFRESH_TOKEN} from "./storage-keys";
import {API_END_POINT} from "./constants";
import {showNotification, sleep} from "./utils";
import {getStore} from "./redux/store";
// eslint-disable-next-line import/no-cycle
import {logout, setUserData} from "./redux/actions/authAction";
import {hideLoader, showLoader, setLoaderMessage} from "./redux/actions/commonUIAction";

const handle401Error = async (apiCallData) => {
  try {
    // eslint-disable-next-line no-use-before-define
    const {userInfo, accessToken, refreshToken} = await callAPI({
      method: 'put',
      url: '/refreshToken',
      hideErrorMessage: true,
      showLoading: true,
      headers: {refreshtoken: `Bearer ${getItem(REFRESH_TOKEN)}`}
    });
    setUserData({userInfo, accessToken, refreshToken, dispatch: getStore().dispatch});
    // eslint-disable-next-line no-use-before-define
    return await callAPI(apiCallData);
  } catch(error){
    getStore().dispatch(logout());
    throw error;
  }
}

const handleError = async ({exception, url, apiData, hideErrorMessage})=>{
  if(exception.response && exception.response.status === 401
    && url!=='/checkLogin' && url!=='/login' && url!=='/refreshToken'){
    try{
      return await handle401Error(apiData);
    } catch(e){
      throw new HttpError(exception.message, exception.status, exception.json);
    }
  } else {
    if(!hideErrorMessage) {
      showNotification({message: exception.message, notificationType: 'error'});
    }
    throw new HttpError(exception.message, exception.status, exception.json);
  }
}

// Manage the loading statuses
let loadingCount = 0;

const setLoader = () =>{
  getStore().dispatch(showLoader());
  loadingCount += 1;
};

const showLoaderMessage=(showMessage)=>{
    getStore().dispatch(setLoaderMessage(showMessage));
}

const removeLoader = () => {
  if (loadingCount) {
    loadingCount -= 1;
  }
  if (loadingCount === 0) {
    getStore().dispatch(hideLoader());
  }
}


// eslint-disable-next-line import/prefer-default-export
export const callAPI = async (apiData) => {
  const {url, method='GET', queryParams={}, formData, body, headers, hideErrorMessage, showLoading = false, showMessage=null, successMessage, isThirdParty} = apiData;
  try {
    if(showLoading){
      setLoader();
      showLoaderMessage(showMessage);
    }

    const requestHeaders = {...headers };
    if(getItem('isNewHope')==='true'){
      requestHeaders.isNewHope=true;
    }
    if(!isThirdParty){
      requestHeaders.accesstoken = `Bearer ${getItem(ACCESS_TOKEN)}`;
      requestHeaders.Authorization = 'Bearer 7f4e5d857756bd5bdccf56245c49108146cbf7fe';
    }

    const response =  await axios.request({
      method,
      url: `${isThirdParty ? '': API_END_POINT}${url}`,
      data: formData || body,
      params: queryParams,
      headers: requestHeaders
    });
    if(successMessage){
      showNotification({message: successMessage, notificationType: 'success', timeout: 10000});
    }
    return isThirdParty ? response.data : response.data.data;
  } catch(exception){
    const errorMessage = exception.response &&
      exception.response.data &&
      exception.response.data.error &&
      exception.response.data.error.Message;
    exception.message = errorMessage || exception.message;
    // eslint-disable-next-line no-return-await
    return await handleError({exception, url, apiData, hideErrorMessage});
  } finally {
    if(showLoading){
      removeLoader();
      showLoaderMessage(null);
    }
  }
};
