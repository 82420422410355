import { RAPID_GRID_ACTIONS } from "../actions/gridAction";

const initialAppState = {};

const gridReducer = (state = initialAppState, action) => {
  switch (action.type) {

    case RAPID_GRID_ACTIONS.RESET_REFRESH_DATA:
      return {
        ...state,
        [action.payload.gridId]: {
          ...state[action.payload.gridId] || {},
          doRefresh: false
        }
      };

    case RAPID_GRID_ACTIONS.REFRESH_GRID_DATA:
      return {
        ...state,
        [action.payload.gridId]: {
          ...state[action.payload.gridId] || {},
          doRefresh: true
        }
      };

    case RAPID_GRID_ACTIONS.SET_SELECTED_ROWS:
      return { ...state,
        [action.payload.gridId]: {
          ...state[action.payload.gridId] || {},
          selectedRows: action.payload.selectedRows
        }
      };

    case RAPID_GRID_ACTIONS.SET_PAGINATION_STATE:
      return {
        ...state,
        [action.payload.gridId]: {
          ...state[action.payload.gridId] || {},
          selectedRows: action.payload.selectedRows
        }
      };

    case RAPID_GRID_ACTIONS.REMOVE_GRID_DATA:
      // eslint-disable-next-line no-case-declarations
      const newState = {...state};
      delete newState[action.payload.gridId]
      return newState;

    default:
      return state;
  }
};

export default gridReducer;
