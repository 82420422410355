import { UI_ACTIONS } from "../actions/commonUIAction";

const initialAppState = {showLoader: false, showMessage: 'Loading', windowSize: {height: 0, width: 0}};

const commonUIReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case UI_ACTIONS.SHOW_LOADER:
      console.trace('Showing the loader from here.');
      return { ...state, showLoader: true };
    case UI_ACTIONS.HIDE_LOADER:
      return { ...state, showLoader: false };
    case UI_ACTIONS.WINDOW_RESIZE:
      return { ...state, windowSize: action.payload };
    case UI_ACTIONS.SET_LOADER_MSG:
      return { ...state, showMessage: (action.payload  || initialAppState.showMessage)};
    default:
      return state;
  }
};
export default commonUIReducer;
