import {filter} from "lodash";
import {getUserData} from "../../../shared/redux/actions/authAction";
import {FILE_UPLOAD_STATUSES} from "../shared/constants";

const processCustomerData=(customerData)=>{
  const returnData = {...customerData};
  if(customerData.isManual){
    returnData.CustomerType = customerData.CustomerType.value.CustomerType;
    returnData.Branch = customerData.Branch.value.BranchName;
    returnData.SalesRep = customerData.SalesRep.value.PrefFullName;
    returnData.SalesRepID=customerData.SalesRep.value.SalesRepID && Number(customerData.SalesRep.value.SalesRepID);
    returnData.IsManager=customerData.SalesRep.value.IsManager;
    returnData.SalesRepEmail=customerData.SalesRep.value.SalesRepEmail;
    returnData.ManagerEmail=customerData.SalesRep.value.ManagerEmail;
  }
  return returnData;
}

// eslint-disable-next-line import/prefer-default-export
export const getAPIObject = ({customerData, dealData, locationData, mainframeData, orderStatus, orderData, attachments}) => {
  const customerObj = processCustomerData(customerData.values.Customer.value);
  const {OrderTypeID, OrderType} = orderData.OrderType.value;
  const DeliveryMethod = orderData.DeliveryMethod.value;
  const {Note} = orderData;
  const dealObj = {...dealData.values};
  const locationArray = [];
  const mainframeArray = [];
  const accessoryArray = [];
  locationData.values.Locations.forEach((location)=>{
    const {LocationUIID} = location;
    locationArray.push({
      LocationUIID,
      ...location.Location.value,
      ...location.ContactInfo,
      LocationExists: !location.Location.value.isManual
    });
    mainframeData[LocationUIID].values.MainFrames.forEach(mainframe=>{
      const {MainframeUIID} = mainframe;
      const Price = parseFloat(mainframe.Price).toFixed(2);
      const Quantity = parseInt(mainframe.Quantity, 10 );
      let Manufacturer = null;
      if(mainframe.MainFrame.value.isManual) {
        Manufacturer = mainframe.MainFrame.value.Manufacturer.value.Manufacturer;
      }
      mainframeArray.push({
        LocationUIID,
        MainframeUIID,
        ...mainframe.MainFrame.value,
        Price,
        Quantity,
        Manufacturer,
        Total: (Price * Quantity).toFixed(2),
        MainframeExists: !mainframe.MainFrame.value.isManual
      });
      if(mainframe.Accessories && mainframe.Accessories.length){
        mainframe.Accessories.forEach(accessory=>{
          const {AccessoryUIID} = accessory;
          const accessoryPrice = parseFloat(accessory.Price).toFixed(2);
          const accessoryQuantity = parseInt(accessory.Quantity, 10 );
          let accessoryManufacturer = null;
          if(accessory.Accessory.value.isManual)
          {
            accessoryManufacturer = accessory.Accessory.value.Manufacturer.value.Manufacturer;
          }
          accessoryArray.push({
            LocationUIID,
            MainframeUIID,
            AccessoryUIID,
            ...accessory.Accessory.value,
            Price: accessoryPrice,
            Quantity: accessoryQuantity,
            Manufacturer: accessoryManufacturer,
            Total: (accessoryPrice * accessoryQuantity).toFixed(2),
            AccessoryExists: !accessory.Accessory.value.isManual
          });
        });
      }
    });
  });

  // eslint-disable-next-line consistent-return
  const attachmentData = filter(attachments, (attachment)=>{
    if(attachment.status === FILE_UPLOAD_STATUSES.UPLOADED && !attachment.isDeleted){
      // eslint-disable-next-line no-underscore-dangle
     return attachment._id;
    }
  });

  return {
    ...dealObj,
    // eslint-disable-next-line no-underscore-dangle
    Attachments: attachmentData && attachmentData.map((attachment)=>attachment._id),
    OrderType,
    OrderTypeID,
    Note,
    EADeliveryMethod: DeliveryMethod,
    DealTitle: dealObj.DealTitle || customerObj.CustomerName,
    CustomerType: customerObj.CustomerType,
    OrderStatus: orderStatus,
    User: getUserData().userPrincipalName,
    CustomerID: customerObj.CustomerID,
    CustomerName: customerObj.CustomerName,
    CustomerExists: !customerObj.isManual,
    CustomerDetails: customerObj,
    Locations: locationArray,
    Mainframe: mainframeArray,
    Accessories: accessoryArray
  }
}
