import Noty from 'noty';
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import {isString as isValidString,isNumber as isValidNumber,isEmpty, compact} from 'lodash'
import {VALIDATION_PATTERN} from "./constants";

export const showNotification = ({message, notificationType, timeout, dismissQueue = false,layout='topRight'}) => {
  if(message && notificationType) {
    if(dismissQueue){
      Noty.closeAll();
    }
    new Noty({
      text: message,
      dismissQueue,
      timeout: timeout || (notificationType === 'warning' ? 10000 : 5000),
      layout,
      type: notificationType,
      theme: "mint",
    }).on('afterShow', function() {
      // clearNotification();
    }).show();
  }
};

export const getRandomString = (length = 16)=>{
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export const toCapitalCase = (string) =>{
  if(!string){
    return string;
  }
  return string[0].toUpperCase() + string.slice(1);
}

export const sleep = (timeout=3000)=>{
  return new Promise((resolve)=>{
    setTimeout(()=>resolve(),timeout);
  });
}


export const isEmail=(value)=>{
   return new RegExp(VALIDATION_PATTERN.EMAIL).test(value);
};

export const isPhone=(value)=>{
  return new RegExp(VALIDATION_PATTERN.PHONE).test((value && value.toString().replace(/-|\(|\)|\[|\]/g,'')));
}

export const isString=(value)=>{
   return isValidString(value);
};

export const isNumber=(value)=>{
  return  isValidNumber(value)
};

export const isEmptyObject=(value)=>{
  return  isEmpty(value)
};

export const compactArray=(array)=>{
  return compact(array)
}
