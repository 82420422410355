import React from 'react'
import { Message } from 'semantic-ui-react'

// eslint-disable-next-line
export const ErrorMessage = ({children ,header}) => (
  <Message negative>
    <Message.Header>{header}</Message.Header>
    {children}
  </Message>
);
