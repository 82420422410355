import React from "react";
import { Button } from 'semantic-ui-react';
import "./buttons.less";

// eslint-disable-next-line react/jsx-props-no-spreading
export const PrimaryButton = (props)=><Button {...props} primary className="ri-button"/>

// eslint-disable-next-line react/jsx-props-no-spreading,react/destructuring-assignment,react/prop-types,react/button-has-type
export const ClearButton = (props)=><button {...props} className={`${props.className || ''} ri-clear-button`}/>

// eslint-disable-next-line react/jsx-props-no-spreading,react/prop-types,react/destructuring-assignment,react/button-has-type
export const AddButton = (props)=><button {...props} className={`${props.className || ''} add-button`}/>

// eslint-disable-next-line react/jsx-props-no-spreading
export const SecondaryButton = (props)=><Button {...props} className='secondary-btn ri-button'/>

