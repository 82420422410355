export const DEAL_ORDER_ACTIONS = {
  CHANGE_STEP: 'CHANGE_STEP',
  UPDATE_DEAL_DETAILS: 'UPDATE_DEAL_DETAILS',
  UPDATE_CUSTOMER_DATA: 'UPDATE_CUSTOMER_DATA',
  UPDATE_LOCATION_DATA: 'UPDATE_LOCATION_DATA',
  UPDATE_MAINFRAME_DATA: 'UPDATE_MAINFRAME_DATA',
  UPDATE_ORDER_DATA: 'UPDATE_ORDER_DATA',
  SET_FULL_DEAL_DATA: 'SET_FULL_DEAL_DATA',
  SET_IS_EDIT: 'SET_IS_EDIT',
  RESET_DEAL_DATA: 'RESET_DEAL_DATA',
  ADD_CUSTOMER_MANUAL_OPTIONS: 'ADD_CUSTOMER_MANUAL_OPTIONS',
  ADD_LOCATION_MANUAL_OPTIONS: 'ADD_LOCATION_MANUAL_OPTIONS',
  ADD_MAINFRAME_MANUAL_OPTIONS: 'ADD_MAINFRAME_MANUAL_OPTIONS',
  ADD_ACCESSORY_MANUAL_OPTIONS: 'ADD_ACCESSORY_MANUAL_OPTIONS',
  UPDATE_DEAL_COMPONENT:'UPDATE_DEAL_COMPONENT',
  SET_ATTACHMENTS:'SET_ATTACHMENTS',
  SET_ATTACHMENT_STATUS:'SET_ATTACHMENT_STATUS',
  SET_ATTACHMENT_DATA:'SET_ATTACHMENT_DATA',
  SET_ALL_FORM_SUBMIT_STATUS:'SET_ALL_FORM_SUBMIT_STATUS',
  SET_FORM_SUBMIT_STATUS: 'SET_FORM_SUBMIT_STATUS',
  RESET_LOCATIONS: 'RESET_LOCATIONS',
  RESET_MAINFRAMES: 'RESET_MAINFRAMES',
  RESET_ACCESSORIES: 'RESET_ACCESSORIES',
  SET_DEAL_SHEET_JSON: 'SET_DEAL_SHEET_JSON',
  RESET_DEAL_SHEET_JSON: 'RESET_DEAL_SHEET_JSON',
  CONFIRM_EXCESSIVE_DEAL:'CONFIRM_EXCESSIVE_DEAL',
  SET_ORDER_STATUS:'SET_ORDER_STATUS'
};

export const changeStep = (step) => {
  return {type: DEAL_ORDER_ACTIONS.CHANGE_STEP, payload: step};
};

export const updateDealDetails = (dealDetails) => {
  return {type: DEAL_ORDER_ACTIONS.UPDATE_DEAL_DETAILS, payload: dealDetails};
};

export const updateCustomerData = (customerData) => {
  return {type: DEAL_ORDER_ACTIONS.UPDATE_CUSTOMER_DATA, payload: customerData};
};

export const updateLocationData = (locationData) => {
  return {type: DEAL_ORDER_ACTIONS.UPDATE_LOCATION_DATA, payload: locationData};
};

export const updateMainframeData = ({LocationUUID, mainframeData}) => {
  return {type: DEAL_ORDER_ACTIONS.UPDATE_MAINFRAME_DATA, payload: {LocationUUID, mainframeData}};
};

export const updateOrderData = (orderData) => {
  return {type: DEAL_ORDER_ACTIONS.UPDATE_ORDER_DATA, payload: orderData};
};

export const setFullDealData = (dealData) => {
  return {type: DEAL_ORDER_ACTIONS.SET_FULL_DEAL_DATA, payload: dealData};
};

export const setIsEdit = (dealData) => {
  return {type: DEAL_ORDER_ACTIONS.SET_IS_EDIT, payload: dealData};
};

export const resetDealData = () => {
  return {type: DEAL_ORDER_ACTIONS.RESET_DEAL_DATA};
};

export const addCustomerManualOptions=(manualOptions)=>{
  return {type: DEAL_ORDER_ACTIONS.ADD_CUSTOMER_MANUAL_OPTIONS, payload: manualOptions};
};

export const addLocationManualOptions=(manualOptions)=>{
  return {type: DEAL_ORDER_ACTIONS.ADD_LOCATION_MANUAL_OPTIONS, payload: manualOptions};
};

export const addMainFrameManualOptions=(manualOptions)=>{
  return {type: DEAL_ORDER_ACTIONS.ADD_MAINFRAME_MANUAL_OPTIONS, payload: manualOptions};
};


export const addAccessoryManualOptions=(manualOptions)=>{
  return {type: DEAL_ORDER_ACTIONS.ADD_ACCESSORY_MANUAL_OPTIONS, payload: manualOptions};
};

export const setAttachments = (attachments)=>{
  return {type: DEAL_ORDER_ACTIONS.SET_ATTACHMENTS, payload: attachments};
}

export const setAttachmentStatus = ({attachment, status})=>{
  return {type: DEAL_ORDER_ACTIONS.SET_ATTACHMENT_STATUS, payload: {attachment, status}};
}

export const setAttachmentData = ({attachment, data})=>{
  return {type: DEAL_ORDER_ACTIONS.SET_ATTACHMENT_DATA, payload: {attachment, data}};
}

export const setFormSubmitStatus =  ({form, status})=>{
  return {type: DEAL_ORDER_ACTIONS.SET_FORM_SUBMIT_STATUS, payload: {form, status}};
}

export const setAllFormSubmitStatus =  (status)=>{
  return {type: DEAL_ORDER_ACTIONS.SET_ALL_FORM_SUBMIT_STATUS, payload: status};
}

export const resetLocations = () =>{
  return {type: DEAL_ORDER_ACTIONS.RESET_LOCATIONS};
}

export const resetMainframes = () =>{
  return {type: DEAL_ORDER_ACTIONS.RESET_MAINFRAMES};
}

export const resetAccessories = (mainframeUIID) =>{
  return {type: DEAL_ORDER_ACTIONS.RESET_MAINFRAMES, payload:{mainframeUIID}};
}

export const setDealSheetJSON=(dealSheetJSON)=>{
  return {type:DEAL_ORDER_ACTIONS.SET_DEAL_SHEET_JSON,payload:dealSheetJSON}
}

export const resetDealSheetJSON=()=>{
  return {type:DEAL_ORDER_ACTIONS.RESET_DEAL_SHEET_JSON}
}

export const setExcessiveDeal=(status)=>{
  return {type:DEAL_ORDER_ACTIONS.CONFIRM_EXCESSIVE_DEAL,payload:status}
}

export const setOrderStatus=(status)=>{
  return {type:DEAL_ORDER_ACTIONS.SET_ORDER_STATUS,payload:status}
}
