import React  from "react";
import {  Switch, Route, Redirect } from "react-router-dom";
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import {isAuthorized} from "./appAuthorized";

const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: state => !!state.auth.user,
  wrapperDisplayName: 'UserIsAuthenticated'
});

const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: () => '/deals',
  allowRedirectBack: false,
  authenticatedSelector: state => state.auth.user === null,
  wrapperDisplayName: 'UserIsNotAuthenticated'
});

const NotFoundRedirect = () => <Redirect to='/deals' />;

const RenderRoutes = ({ match , routes }) => {
  // console.trace(JSON.stringify(routes));
  if (routes && routes.length) {
    const renderRoutes = [];
    for(let [index, route] of routes.entries()) {
        const routePath = match ? `${match.url}/${route.path}` : route.path;
        const { authenticated, path, component, ...rest } = route;

        let wrappedComponent = component;
        if (authenticated === true) {
          wrappedComponent = userIsAuthenticated(component);
        } else if (authenticated === false){
          wrappedComponent = userIsNotAuthenticated(component);
        }
        if(route.token && !isAuthorized(route.token)) {
          wrappedComponent = userIsNotAuthenticated(component);
        }
        renderRoutes.push(<Route key={index}
                                 path={routePath}
                                 component={wrappedComponent} {...rest} />);
    }
    renderRoutes.push(<Route key={-1} component={NotFoundRedirect}/>);
    return <Switch>{ renderRoutes }</Switch>;
  } else {
    return null;
  }
};

export default RenderRoutes;
