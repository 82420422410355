
export const setItem = (key, value) => {
    localStorage.setItem(key, value);
};

export const getItem = (key) =>{
    return localStorage.getItem(key);
};

export const removeItem = (key) =>{
    localStorage.removeItem(key);
};

export const setJSONItem = (key, value) =>{
    value = JSON.stringify(value);
    localStorage.setItem(key, value);
};

export const getJSONItem = (key) =>{
    let returnValue = {};
    try{
        returnValue = JSON.parse(localStorage.getItem(key));
    } catch(e){//do nothing
        console.error(e);
    }
    return returnValue;
};
