import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

let _store = null;
export function getStore() {
  return _store;
}

export function configureStore(rootReducer, initialState) {
  const composeEnhancers = composeWithDevTools({});
  _store = createStore(rootReducer, initialState,
    composeEnhancers(
      applyMiddleware(thunk) //Applying this middleware to handle the actions with async operations
    )
  );
  return _store;
}
