import React  from 'react';
import {Dimmer, Loader} from 'semantic-ui-react'
import { connect } from 'react-redux';
import PropTypes from "prop-types";

const setLoadingClassToBody = () => {
  const element = document.body;
  const name = "loading";
  const arr = element.className.split(" ");
  if (arr.indexOf(name) === -1) {
    element.className += ` ${name}`;
  }
};

const removeLoadingClassToBody = () => {
  const element = document.body;
  element.className = element.className.replace(/\bloading\b/g, "");
};

export const StaticSpinner = ({ showLoader = true ,showMessage}) =>{
  if (showLoader === false) {
    removeLoadingClassToBody();
  } else {
    setLoadingClassToBody();
  }
  return (
    <Dimmer className='loader-dimmer' active={showLoader} page>
      <Loader size="big">{showMessage}</Loader>
    </Dimmer>
  )
}

StaticSpinner.propTypes = {
  showLoader: PropTypes.bool.isRequired,
  showMessage: PropTypes.string.isRequired
};


const mapStateToProps = (state) => ({
  showLoader: state.commonUI.showLoader,
  showMessage:state.commonUI.showMessage});
export const Spinner = connect(mapStateToProps)(StaticSpinner);
